import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import {
  GrantedBy,
  useGrantDetailsStore,
} from "../../store/useGrantDetailsStore";
import SearchDropDown from "../../components/shared/SearchDropdown";
import { useEmployees, useEsopPlans, useGrants } from "../../queries";
import { EmployementStatus } from "../../types/Employee";
import { useCompanyStore } from "../../store";
import canUserBeAssignedToThisPlan from "../../utils/grantRule";
import { Select } from "../../components/shared/Select";
import { getGrantPPS } from "../../api/Esop";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { PlanState } from "../../types/EsopPlan";

interface NewGrantPageProps {
  onStepChange: (e?: number) => void;
  onBackClick: () => void;
}

const NewGrantPage = (props: NewGrantPageProps) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const { companyData } = useCompanyStore();
  const data = useGrantDetailsStore();
  const { mode, id } = useParams();
  const navigate = useNavigate();
  const { data: _employees } = useEmployees();
  const employees = _.sortBy(_employees || [], "employeeName");
  const employedEmployees = employees.filter(
    (employee) =>
      employee.employmentStatus === EmployementStatus.EMPLOYED ||
      employee.employmentStatus === EmployementStatus.PENDING
  );
  const { data: grants } = useGrants();
  const { data: _plans, isFetching: plansFetching } = useEsopPlans();
  const plans = _plans || [];
  const plansToShow = useMemo(() => {
    const selectedEmployee = employees.find(
      (employee) => employee.id === data?.employee?.id
    );
    if (selectedEmployee)
      return (
        plans.filter(
          (plan) =>
            plan.esopPlanState === PlanState.ACTIVE &&
            canUserBeAssignedToThisPlan(
              plan.planType ?? "",
              selectedEmployee.employmentType?.toString() ?? "",
              companyData?.currency ?? "INR - ₹"
            )
        ) || []
      );
    return plans || [];
  }, [data?.employee, plansFetching]);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (!data.grantedBy) data.setGrantedBy(GrantedBy.OPTIONS);
  }, [data]);

  const checkIfGrantIdExists = (value: string, mode = "add", id = "") => {
    if (grants) {
      const filteredGrants =
        mode === "edit"
          ? grants.filter((g) => g.optionHolderId !== id)
          : grants;
      return filteredGrants.some((grant) => grant.grantIdentifier === value);
    } else return false;
  };

  const validationSchema = Yup.object().shape({
    employee: Yup.object().required("Name of Holder is required"),
    plan: Yup.object().required("Plan Name is required"),
    dateOfGrant: Yup.date()
      .required("Grant Date is required")
      .when("employee", (employee, schema) =>
        schema.test(
          "is-after-joining-and-plan-start",
          `Grant Date must be after the employee's joining date and the plan start date.`,
          // eslint-disable-next-line func-names
          function (value) {
            const { plan } = this.parent as {
              plan?: { planStartDate?: Date; startDate?: Date };
            };
            const employeeJoiningDate = employee?.dateOfJoin;

            const startDate = plan?.planStartDate || plan?.startDate;

            if (!value || !employeeJoiningDate || !startDate) return true;

            const grantDate = new Date(value).setHours(0, 0, 0, 0);
            const joiningDate = new Date(employeeJoiningDate).setHours(
              0,
              0,
              0,
              0
            );
            const planStartDate = new Date(startDate).setHours(0, 0, 0, 0);

            return grantDate >= joiningDate && grantDate >= planStartDate;
          } as (this: Yup.TestContext, value: Date) => boolean
        )
      ),
    grantId: Yup.string().test(
      "unique-grant-id",
      "This Grant ID already exists.",
      (value) => {
        if (!value) return true;
        const exists = checkIfGrantIdExists(value, mode, id);
        return !exists;
      }
    ),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  function updateSharePrice() {
    if (companyData?.companyMetaData.typeOfTheCompany === "PUBLIC") {
      getGrantPPS(data.dateOfGrant)
        .then((ppsData: any) => {
          const sharePriceOnGrantDate =
            ppsData?.data?.sharePriceOnGrantDate || 0;
          if (
            sharePriceOnGrantDate &&
            !Number.isNaN(parseFloat(sharePriceOnGrantDate.toFixed(4)))
          ) {
            const sharePrice = parseFloat(sharePriceOnGrantDate.toFixed(4));
            data.setSharePrice(sharePrice);
            const value = data?.plan.isFractional
              ? parseFloat(data.optionsGranted.toFixed(4))
              : parseInt(data.optionsGranted.toString(), 10);
            if (value >= 0 && (sharePrice || 0) >= 0) {
              data.setValueOfOptions(
                parseFloat((value * (sharePrice || 0)).toFixed(4))
              );
            }
          }
        })
        .catch((err) => {});
    }
  }

  function handleSubmit() {
    formik
      .validateForm({
        employee: data.employee,
        grantId: data.grantId,
        plan: data.plan,
        dateOfGrant: data.dateOfGrant,
        grantPrice: data.grantPrice || 0,
      })
      .then((validationErrors) => {
        if (_.isEmpty(validationErrors)) {
          updateSharePrice();
          props.onStepChange();
        } else {
          setErrors(validationErrors);
        }
      });
  }

  return (
    <VStack className="w-full">
      {/* Title and Description */}
      <VStack className="justify-between gap-1 pb-3 pl-6 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          New Grant
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Select the grant type and enter the basic details of the new grant you
          wish to create
        </HStack>
      </VStack>

      {/* Form Fields */}
      <VStack className="gap-4 px-6 pt-4">
        {/* Grant By Options */}
        <VStack>
          <HStack className="items-center justify-between gap-8 px-4 py-4 pr-40 text-center">
            <HStack className="items-center gap-2 text-center">
              <input
                type="radio"
                className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                checked={data.grantedBy === GrantedBy.OPTIONS}
                onChange={() => data.setGrantedBy(GrantedBy.OPTIONS)}
              />
              <Label className="pt-2 text-sm font-normal">
                Grant By Options
              </Label>
            </HStack>
            <HStack className="items-center gap-2">
              <input
                type="radio"
                className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                checked={data.grantedBy === GrantedBy.AMOUNT}
                onChange={() => data.setGrantedBy(GrantedBy.AMOUNT)}
              />
              <Label className="pt-2 text-sm font-normal">
                Grant By Amount
              </Label>
            </HStack>
            <HStack className="items-center gap-2">
              <input
                type="radio"
                className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                checked={data.grantedBy === GrantedBy.MODEL}
                onChange={() => data.setGrantedBy(GrantedBy.MODEL)}
              />
              <Label className="pt-2 text-sm font-normal">Auto Grant</Label>
            </HStack>
          </HStack>
        </VStack>

        {/* Name of Holder */}
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Name of Holder</Label>
            <Select
              placeholder="--Select--"
              options={employedEmployees}
              disabled={mode === "edit"}
              value={data.employee?.id || ""}
              textGetter={(e) =>
                `${e.employeeName}(${e.employeeIdentificationString})`
              }
              valueGetter={(e) => e.id}
              onChange={(e) => {
                const employee = employedEmployees.find(
                  (emp) => emp.id === e.target.value
                );
                if (employee) data.setEmployee(employee);
              }}
              className=""
            />
            {errors.employee && <Error text={errors.employee} />}
          </div>

          {/* Grant ID */}
          <div className="flex-1">
            <Label className="text-sm font-normal">Grant ID (Optional)</Label>
            <Input
              type="text"
              placeholder="Enter Unique Grant ID"
              onChange={(e) => data.setGrantId(e.target.value)}
              value={data.grantId}
            />
            {errors.grantId && <Error text={errors.grantId} />}
          </div>
        </HStack>

        {/* Plan Name and Conversion Ratio */}
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Name</Label>
            <Select
              onChange={(e) => {
                const plan = plansToShow.find(
                  (p) => p.esopPlanId === e.target.value
                );
                if (!plan) return;
                data.setPlan(plan);
                const conversionRatio = parseFloat(
                  (1 / (plan?.conversionShares || 1)).toFixed(4)
                );
                data.setConversionRatio(
                  `${conversionRatio} ${
                    conversionRatio > 1 ? "Options" : "Option"
                  } : 1 Share`
                );
              }}
              placeholder="--Select--"
              options={plansToShow}
              value={data.plan?.esopPlanId || ""}
              className=""
              textGetter={(e) => e.planName}
              valueGetter={(e) => e.esopPlanId}
            />
            {errors.plan && <Error text={errors.plan} />}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Grant Type (Optional)</Label>
            <Input
              type="text"
              placeholder="Enter Grant Type"
              onChange={(e) => data.setGrantType(e.target.value)}
              value={data.grantType}
            />
          </div>
        </HStack>

        {/* Grant Date and Grant Price */}
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Grant Date</Label>
            <Input
              type="date"
              onChange={(e) => data.setDateOfGrant(e.target.value)}
              value={data.dateOfGrant}
            />
            {errors.dateOfGrant && <Error text={errors.dateOfGrant} />}
          </div>
          <div className="flex-1"></div>
        </HStack>

        {/* Navigation Buttons */}
        <HStack className="justify-between pt-4">
          <ButtonPrimary1
            onClick={() => {
              data.clear();
            }}
          >
            Clear
          </ButtonPrimary1>
          <ButtonPrimary onClick={() => handleSubmit()}>Next</ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default NewGrantPage;
