import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import {
  CellClickedEvent,
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  SideBarDef,
  ValueParserParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { useAuthStore } from "../../store";
import { Employee } from "../../types/Employee";
import Tooltip from "../../components/shared/Tooltip";

import {
  Box,
  HStack,
  IconCTAButton,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import Avatar from "../esopOverview/Avatar";
import { Action, CTADropdown } from "../../components/shared/Dropdown";

import {
  capitalizeFirstLetter,
  limitString,
  stringFormat,
} from "../../utils/string";
import QuickSearch from "../../components/shared/QuickSearch";
import SearchInput from "../../components/shared/SearchInput";
import { useGetFilterStates } from "../../queries/gridUtils";
import MyCustomFilters from "../../components/shared/MyCustomFilters";
import { saveTableFilterState } from "../../api/gridUtils";
import { AvailableTableFilters, SaveTableState } from "../../types/Table";
import StatusLabelColors from "../../constants/StatusLabelColors";
import { TableId } from "../../constants/TableIdConstants";
import { useTableStateManagement } from "../../components/shared/TableHook";
import CloudSetting from "../../components/shared/CloudIcon";

function EmployeesTableAgGrid({
  handleAction,
  employeeTableData,
  onSelectedRows,
  gridRef,
  tableFilterCount,
}: {
  employeeTableData: Employee[];
  handleAction: (action: Action, employees: Employee[]) => void;
  onSelectedRows: (item: Employee[]) => void;
  gridRef: any;
  tableFilterCount: Function;
}) {
  employeeTableData.sort(
    (a, b) =>
      new Date(b.dateOfJoin).getTime() - new Date(a.dateOfJoin).getTime()
  );

  const gridApi = useRef<GridApi | any>(null);
  // route params logic
  const navigate = useNavigate();

  const componentsRegistery = useMemo(
    () => ({
      employeeInfo: memo(EmployeeInfoRender),
      vestedFactor: memo(EmployeeVestedFactor),
    }),
    []
  );

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const { data, refetch } = useGetFilterStates("EMPLOYEES");

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const rowData = employeeTableData.find(
        (row) =>
          row.employeeIdentificationString === cellParams?.data?.employeeId
      );
      if (rowData) {
        navigate(`/options/employeeDetails/${rowData.id}`);
      }
    }
  };

  const saveNewFilter = (filterName: string) => {
    const filterState = gridApi.current?.api.getFilterModel();
    const filterStateToSave: SaveTableState = {
      filterName,
      filterState,
      tableName: "EMPLOYEES",
    };
    saveTableFilterState(filterStateToSave).then((_) => {
      toast(`${filterName} saved successfully`, {
        autoClose: 2000,
        type: "success",
      });
      refetch();
    });
  };
  const onFilterSelected = (filterItem: AvailableTableFilters | null) => {
    gridApi?.current?.api.setFilterModel(filterItem?.filterState);
  };
  function numberValueParser(params: ValueParserParams) {
    const res = Number.parseInt(params.newValue, 10);
    if (Number.isNaN(res)) {
      return undefined;
    }
    return res;
  }
  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
    onSelectedRows(selectedRows);
  };
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    tableState,
  } = useTableStateManagement(gridApi, TableId.employeeTable);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "EMPLOYEE NAME",
        checkboxSelection: true,
        width: 200,
        autoHeight: true,
        cellStyle: { paddingTop: "15px", lineHeight: "20px" },
        wrapText: true,
        headerCheckboxSelection: true,
        field: "employeeInfo",
        filter: "agMultiColumnFilter",

        getQuickFilterText: (params) => {
          const {
            employeeName,
            employeeDesignation,
            department,
            employmentStatus,
          } = params?.value || {};

          const valuesToPass = [
            employeeName,
            employeeDesignation,
            department,
            employmentStatus,
          ].filter((value) => value !== undefined && value !== null);
          return valuesToPass.toString();
        },

        initialWidth: 200,
        minWidth: 200,
        sortable: true,
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Employee Name",
              display: "subMenu",
              filter: "agSetColumnFilter",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                suppressSelectAll: false,
                suppressMiniFilter: false,
                keyCreator: (params: any) => {
                  const employeeName = params.value.employeeName;
                  return employeeName;
                },
                valueFormatter: (params: any) => {
                  const employeeName = params.value.employeeName;
                  return employeeName;
                },
              },
            },
            {
              title: "Designation",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                suppressSelectAll: false,
                suppressMiniFilter: false,
                comparator: sortComparator,

                keyCreator: (params: any) => {
                  const employeeDesignation = params.value.employeeDesignation;
                  return employeeDesignation;
                },
                valueFormatter: (params: any) => {
                  const employeeDesignation = params.value.employeeDesignation;

                  return employeeDesignation;
                },
              },
            },
            {
              title: "Department",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                suppressSelectAll: false,
                suppressMiniFilter: false,
                comparator: sortComparator,

                keyCreator: (params: any) => {
                  const department = params.value.department;
                  return department;
                },
                valueFormatter: (params: any) => {
                  const department = params.value.department;

                  return department;
                },
              },
            },
          ],
        },
        comparator(valueA, valueB, ..._other) {
          return valueA.employeeName
            ?.toLowerCase()
            ?.localeCompare(valueB.employeeName?.toLowerCase());
        },
        menuTabs: ["filterMenuTab"],
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: "employeeInfo",
      },

      {
        headerName: "EMPLOYMENT STATUS",
        minWidth: 175,
        field: "employmentStatus",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        comparator(valueA, valueB, ..._other) {
          return valueA?.props?.employmentStatus
            ?.toLowerCase()
            ?.localeCompare(valueB?.props?.employmentStatus?.toLowerCase());
        },
        filterParams: {
          buttons: ["reset"],
          suppressSelectAll: false,
          suppressMiniFilter: false,
        },
        filterValueGetter: (params) =>
          params?.getValue("employmentStatus")?.props?.employmentStatus,
        cellRendererParams: ({ value }: { value: any }) => value.props,
        menuTabs: ["filterMenuTab"],
        cellRenderer: EmployementStatusRender,
      },
      {
        headerName: "DATE OF JOINING",
        width: 200,
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        field: "dateofjoining",
        sortable: true,
        filter: "agMultiColumnFilter",
        autoHeight: true,
        wrapText: true,
        comparator(valueA, valueB, ..._other) {
          const valueADate = new Date(valueA.props.dateOfJoin)?.getTime();
          const valueBDate = new Date(valueB.props.dateOfJoin)?.getTime();
          return valueADate - valueBDate;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Employee Date",
              display: "subMenu",
              filterParams: {
                maxNumConditions: 5,
                inRangeInclusive: true,
                buttons: ["reset"],
                comparator: (
                  filterLocalDateAtMidnight: Date,
                  cellValue: { props: Employee }
                ) => {
                  const filterDate = filterLocalDateAtMidnight;
                  filterDate.setHours(0, 0, 0, 0);
                  const cellDate = new Date(cellValue.props?.dateOfJoin);
                  cellDate.setHours(0, 0, 0, 0);
                  const filterDateAsEpoch = filterDate.valueOf();
                  const cellEpoch = cellDate.valueOf();
                  return cellEpoch - filterDateAsEpoch;
                },
              },
              filter: "agDateColumnFilter",
            },
            {
              title: "Resignation Date",
              display: "subMenu",
              filterParams: {
                maxNumConditions: 5,
                inRangeInclusive: true,
                buttons: ["reset"],
                comparator: (
                  filterLocalDateAtMidnight: Date,
                  cellValue: { props: Employee }
                ) => {
                  const filterDate = filterLocalDateAtMidnight;
                  filterDate.setHours(0, 0, 0, 0);
                  const cellDate = new Date(cellValue.props?.lastDay || "");
                  cellDate.setHours(0, 0, 0, 0);
                  const filterDateAsEpoch = filterDate.valueOf();
                  const cellEpoch = cellDate.valueOf();
                  return cellEpoch - filterDateAsEpoch;
                },
              },
              filter: "agDateColumnFilter",
            },
          ],
        },

        menuTabs: ["filterMenuTab"],
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: EmployeeDate,
      },
      {
        headerName: "EMPLOYEE ID",
        width: 200,
        field: "employeeId",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("employeeId"),
        filterParams: {
          ...filterparams,
          buttons: ["reset"],
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          comparator: sortComparator,
        },
        menuTabs: ["filterMenuTab"],
        comparator(valueA, valueB, ..._other) {
          return valueA?.localeCompare(valueB);
        },
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "EMAIL ID",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" },
        field: "email",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("email"),
        filterParams: {
          buttons: ["reset"],
          suppressSelectAll: false,
          suppressMiniFilter: false,
          comparator: sortComparator,
        },
        comparator(valueA, valueB, ..._other) {
          return valueA?.localeCompare(valueB);
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "VESTED FACTOR",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" },
        field: "vestedFactorData",
        filter: "agMultiColumnFilter",
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Vested Factor",
              display: "subMenu",
              filter: "agNumberColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) =>
                params.data.vestedFactorData.vestedFactor,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.data.vestedFactorData.vestedFactor,
              },
            },
            {
              title: "Pause Vesting",
              display: "subMenu",
              filter: "agSetColumnFilter",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                suppressSelectAll: false,
                suppressMiniFilter: false,
                keyCreator: (params: any) => {
                  const value =
                    params.value.pausedGrants > 0 ? "VESTING PAUSED" : "NONE";
                  return value;
                },
                valueFormatter: (params: any) => {
                  const value =
                    params.value.pausedGrants > 0 ? "VESTING PAUSED" : "NONE";

                  return value;
                },
              },
            },
          ],
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
        cellRenderer: EmployeeVestedFactor,
      },
      {
        headerName: "NO OF GRANTS",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" },
        field: "noOfGrants",
        filter: "agNumberColumnFilter",
        filterValueGetter: (params) => params.getValue("noOfGrants"),
        filterParams: {
          comparator: sortComparator,
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "LOGIN STATUS",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" },
        field: "lastlogin",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("lastlogin"),
        filterParams: {
          suppressSelectAll: false,
          suppressMiniFilter: false,
          buttons: ["reset"],
        },

        sortable: false,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "CREDENTIALS GENERATED",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" },
        field: "generateCredential",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("generateCredential"),
        filterParams: {
          buttons: ["reset"],
          suppressSelectAll: false,
          suppressMiniFilter: false,
          comparator: sortComparator,
        },
        sortable: false,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
    ],
    []
  );

  const rowData = useMemo(
    () =>
      employeeTableData?.map((employee) => ({
        avatar: (
          <Avatar name={employee.employeeName} status={employee.employeeName} />
        ),

        employeeInfo: {
          employeeName: employee.employeeName,
          employeeDesignation: employee.employeeDesignation,
          department: employee.department,
          employmentStatus: employee.employmentStatus,
        },
        dateofjoining: (
          <EmployeeDate
            dateOfJoin={employee.dateOfJoin}
            lastDay={employee.lastDay}
          />
        ),
        employmentStatus: (
          <EmployementStatusRender
            employmentStatus={
              employee?.isResignationSchedule
                ? "RESIGNATION SCHEDULED"
                : employee.employmentStatus
            }
            isResignationSchedule={employee?.isResignationSchedule ?? false}
          />
        ),
        isResignationSchedule: employee.isResignationSchedule,
        employeeId: employee.employeeIdentificationString,
        email: employee.email,
        vestedFactor: employee.totalPercentageVested * 100,
        vestedFactorData: {
          vestedFactor: employee.totalPercentageVested * 100,
          pausedGrants: employee.pausedGrants || 0,
        },
        noOfGrants: employee.noOfGrants,
        lastlogin: employee.lastLogin ? "Yes" : "No",
        generateCredential: employee.isLoginProvided ? "Yes" : "No",
      })),
    [employeeTableData]
  );
  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.api.paginationSetPageSize(Number(value));
  }, []);
  const onGridReady = (params: any) => {
    gridApi.current = params;
    gridRef.current = params.api;
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel();

    const filteredData = gridApi.current?.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);

    setFilteredRowData(filteredData);
    gridApi.current?.api.setFilterModel(tableState?.filterState);
  }, [
    gridApi,
    tableState?.filterState,
    tableState?.columnState,
    employeeTableData,
  ]);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    const filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    setIsFilterApplied(filtersApplied);
    tableFilterCount(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter(filterModel);
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  return (
    <VStack className="w-full">
      <HStack className="items-center justify-between w-full gap-4 py-4">
        <HStack className="items-center gap-4 px-6">
          <SearchInput
            className=""
            placeholder="Search"
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
        </HStack>

        <HStack className="items-center justify-end gap-4">
          <CloudSetting
            tableId={TableId.employeeTable}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
            gridApi={gridApi}
          />
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isColumnOpen}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
          <MyCustomFilters
            currentFilterState={gridApi.current?.api.getFilterModel()}
            availableFilters={data || []}
            onFilterSelect={onFilterSelected}
            className={""}
            onNewFilterAddition={(e) => {
              saveNewFilter(e);
            }}
          ></MyCustomFilters>
        </HStack>
      </HStack>

      {/* <HStack className="items-center justify-end bg-white rounded-md">
        <HStack className="items-center justify-start">
          <QuickSearch
            placeholder="Search by name, status or dept"
            onChange={(e) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />

          <div
            className={`pr-2 ${
              isColumnOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("columns")}>
              Columns
            </button>
          </div>
          <div
            className={`pr-2 ${
              isFilterOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("filters")}>
              Filters
            </button>
          </div>
        </HStack>
      </HStack> */}
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            alwaysShowHorizontalScroll
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            components={componentsRegistery}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            onCellClicked={handleCellClick}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            onSelectionChanged={handleSelectionChanged}
            suppressRowClickSelection={true}
            pagination={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}

function EmployeeInfoRender(emp: any) {
  return (
    <HStack>
      <Avatar
        name={emp.value.employeeName}
        status={emp.value.employmentStatus}
      />
      <Box className="ml-2">
        <p className="text-xs font-medium text-gray-dark">
          {emp.value.employeeName}
        </p>
        <p className="capitalize text-xxs text-gray-light">
          {(emp.value.employeeDesignation !== null
            ? emp.value.employeeDesignation
            : ""
          ).toLowerCase() || " "}
          |{" "}
          {(emp.value.department !== null
            ? emp.value.department
            : ""
          ).toLowerCase() || " "}
        </p>
      </Box>
    </HStack>
  );
}
function EmployementStatusRender(emp: {
  employmentStatus: string;
  isResignationSchedule: boolean;
}) {
  const statuses = ["EMPLOYED", "RESIGNED", "PENDING", "RESIGNATION SCHEDULED"];
  const status = emp.isResignationSchedule
    ? "RESIGNATION SCHEDULED"
    : (emp.employmentStatus?.toUpperCase() as
        | "EMPLOYED"
        | "RESIGNED"
        | "PENDING"
        | "RESIGNATION SCHEDULED");
  if (!statuses.includes(status)) return <></>;
  const { color, backgroundColor } = StatusLabelColors[status];
  return (
    <HStack>
      <Box className="min-h-4 ">
        <span
          className={`flex items-center capitalize min-w-10 h-6  rounded-sm font-medium text-center text-[9px]  leading-4 px-1`}
          style={{ color, backgroundColor }}
        >
          {emp.isResignationSchedule ? "RESIGNATION SCHEDULED" : status}
        </span>
      </Box>
    </HStack>
  );
}

function EmployeeDate(emp: { dateOfJoin?: string; lastDay?: string }) {
  const formattedDate = new Date(emp.dateOfJoin || " ")
    .toUTCString()
    .slice(5, 17);
  const formattedLastDay = new Date(emp.lastDay || "")
    .toUTCString()
    .slice(5, 17);
  return (
    <Box className="">
      <HStack>
        <Box>
          <p className="text-xs font-medium text-gray-dark">{formattedDate}</p>
          {emp.lastDay && (
            <p className="capitalize text-xxs text-gray-light">{`Resigned on: ${formattedLastDay}`}</p>
          )}
        </Box>
      </HStack>
    </Box>
  );
}

function EmployeeVestedFactor(data: any) {
  const value =
    data.value.vestedFactor != null
      ? `${parseFloat(data.value.vestedFactor.toFixed(2))}%`
      : "0%";
  const { color, backgroundColor } = StatusLabelColors.PENDING;
  return (
    <HStack>
      <VStack className="ml-2">
        <p className="text-xs font-medium text-gray-dark">{value}</p>
        {(data.value.pausedGrants || 0) > 0 && (
          <span
            className={`flex items-center capitalize min-w-10 h-6  rounded-sm font-medium text-center text-[9px]  leading-4 px-1 whitespace-nowrap mt-1 mb-1`}
            style={{ color, backgroundColor }}
          >
            VESTING PAUSED
          </span>
        )}
      </VStack>
    </HStack>
  );
}

export default EmployeesTableAgGrid;
