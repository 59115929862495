import { Icon } from "@iconify/react";
import { Dialog, DialogProps } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useRevertResignation } from "../../queries/employees";
import {
  Employee,
  EmployeeCredentialGeneration,
  RevertResignation,
} from "../../types/Employee";
import { Box, ButtonPrimary, ButtonSecondary, HStack, VStack } from "../utils";
import { CheckBoxWithLabel } from "./InputField";

export type RevertResignationTypes = {
  revertResignation: RevertResignation;
  onSecondaryAction: () => void;
  isRevertResignation: boolean;
  onClose: () => void;
} & DialogProps;

function RevertResignationDialog({
  revertResignation,
  onSecondaryAction,
  isRevertResignation,
  onClose,
  ...dialogProps
}: RevertResignationTypes) {
  const { mutate: revertResignationCall, isLoading } = useRevertResignation();
  const [vestAsPerToday, setVestAsPerToday] = useState(
    revertResignation.vestAsPerToday
  );
  const [sendEmailForVestings, setSendEmailForVestings] = useState(false);
  const [resumeFutureVestings, setResumeFutureVestings] = useState(false);
  const [
    revertAccessBackToPrimaryEmailId,
    setRevertAccessBackToPrimaryEmailId,
  ] = useState(false);
  function revertResignations() {
    revertResignationCall(
      {
        ...revertResignation,
        vestAsPerToday,
        resumeFutureVestings,
        revertAccessBackToPrimaryEmailId,
        sendEmailForVestings,
      },
      {
        onSuccess: () => {
          toast("Resignation reverted successfully!", {
            type: "success",
            autoClose: 2000,
          });
          onSecondaryAction();
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  function discardResignations() {
    revertResignationCall(
      {
        ...revertResignation,
        vestAsPerToday: false,
        resumeFutureVestings,
        revertAccessBackToPrimaryEmailId: false,
        sendEmailForVestings: false,
        note: "Discard Resignation",
        validateResignedState: false,
      },
      {
        onSuccess: () => {
          toast("Resignation discarded successfully!", {
            type: "success",
            autoClose: 2000,
          });
          onSecondaryAction();
        },
        onError: (err: any) => {
          toast(err?.response?.data?.errorRaw || "Something went wrong", {
            type: "error",
            autoClose: 5000,
          });
        },
      }
    );
  }
  return (
    <Dialog {...dialogProps} maxWidth="lg">
      <VStack className="justify-between p-9 bg-white rounded h-[500px]">
        <VStack className="items-center justify-center gap-6 grow">
          {<Icon icon="ep:warning" color="#FFE99B" height={72} />}
        </VStack>
        <p className="items-center justify-center gap-4 font-medium text-center text-dark grow text-xs3">
          {`Are you sure you want to ${
            isRevertResignation ? "revert" : "discard"
          } resignation?`}
        </p>
        {isRevertResignation && (
          <HStack className="gap-4 p-2 text-blue-800 bg-blue-100 rounded">
            <VStack>
              <div className="pt-0">
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium text-blue-800 rounded cursor-pointer"
                />
              </div>
            </VStack>
            <VStack>
              <p className="">{`${revertResignation.employeeIds.length} employees selected
            `}</p>{" "}
            </VStack>
          </HStack>
        )}
        <VStack className="justify-start gap-4 pt-4 grow">
          {isRevertResignation && (
            <>
              <CheckBoxWithLabel
                label={`Vest until today`}
                checked={vestAsPerToday}
                labelclassname={"ml-2 text-xs2 text-dark"}
                onChange={(e) => setVestAsPerToday(e.target.checked)}
              />
              <CheckBoxWithLabel
                label={`Send vesting notification emails`}
                checked={sendEmailForVestings}
                labelclassname={"ml-2 text-xs2 text-dark"}
                onChange={(e) => setSendEmailForVestings(e.target.checked)}
              />
            </>
          )}
          <CheckBoxWithLabel
            label={`Resume future vestings if paused`}
            checked={resumeFutureVestings}
            labelclassname={"ml-2 text-xs2 text-dark"}
            onChange={(e) => setResumeFutureVestings(e.target.checked)}
          />
          {isRevertResignation && (
            <CheckBoxWithLabel
              label={`Revert access to primary email if transferred to personal email`}
              checked={revertAccessBackToPrimaryEmailId}
              labelclassname={"ml-2 text-xs2 text-dark"}
              onChange={(e) =>
                setRevertAccessBackToPrimaryEmailId(e.target.checked)
              }
            />
          )}
        </VStack>
        {!isRevertResignation && (
          <div className="mb-4 -mt-5">
            <DiscardResignationWarningNotes />
          </div>
        )}
        <HStack className="justify-between pt-4 gap-9">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            {"Cancel"}
          </ButtonSecondary>
          <ButtonPrimary
            onClick={() => {
              if (isRevertResignation) {
                revertResignations();
              } else {
                discardResignations();
              }
            }}
            loading={isLoading}
          >
            {isRevertResignation ? "Revert Resignation" : "Discard Resignation"}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Dialog>
  );
}

function DiscardResignationWarningNotes() {
  const notes = [
    `Any Pending Exercise Requests raised during resignation process must be resolved manually`,
    `Resignation and Last Working dates if set will be reverted`,
    `Extended Exercise dates will be set back to expiry date based on grant's plan`,
    `Full and final Settlement Letter if generated or uploaded will be discarded`,
  ];

  return (
    <VStack className="p-2 text-black-501 bg-yellow-100 rounded">
      <HStack className="pt-0 justify-start">
        <HStack className="gap-1 pl-1 items-center">
          <Icon
            icon="material-symbols:info-outline-rounded"
            height={18}
            width={18}
            className="w-full text-xs font-medium text-black-501 rounded"
          />
          <p className="text-sm">Note </p>
        </HStack>
      </HStack>
      <Box className="pl-5 pt-2 pb-1">
        <ul className="list-disc">
          {notes.map((note, index) => (
            <li key={index} className="text-xs pt-0.5">
              {note}
            </li>
          ))}
        </ul>
      </Box>
    </VStack>
  );
}

export default RevertResignationDialog;
