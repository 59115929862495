import { Dialog } from "@mui/material";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import AlertDialog from "../../components/shared/AlertDialog";
import { CollapsibleComponent } from "../../components/shared/Collapsible";
import {
  CheckBoxWithLabel,
  Input,
  Label,
} from "../../components/shared/InputField";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import { ExtendExercise } from "../../modals/ExtendExercise";
import {
  useCompleteResignation,
  useDiscardResignation,
  useEmployeePageDetails,
  useGetResignationDetails,
  useResignationProcess,
} from "../../queries/employees";
import { useError } from "../../store/errorStore";
import { EmployementStatus, RevertResignation } from "../../types/Employee";
import {
  FafLetterStatus,
  GrantDetailsForExercise,
  GrantDetailsForExtendExercise,
  GrantStatusForResignation,
  ResignationDto,
} from "../../types/EmployeeResignation";
import pdfLogo from "../../utils/assets/pdf.svg";
import convertToBase64 from "../../utils/convertToBase64";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDate } from "../../utils/date";
import { downloadS3File } from "../../utils/DownloadFile";
import RevertResignationDialog from "../../components/shared/RevertResignationDialog";

export const Resignation = ({ setActiveTab }: { setActiveTab: Function }) => {
  const { id } = useParams();
  const _id = id ?? "";

  // this should be called refetch and in hook enabled should be used
  const { data: employee } = useEmployeePageDetails(_id);

  const employeeName = employee?.employeeName ?? "";
  const isResignationComplete = employee?.status === EmployementStatus.RESIGNED;
  const [openField, setOpenField] = useState("");
  const [enablePauseVesting, setEnablePauseVesting] = useState(false);
  const [enableLastWorkingDay, setEnableLastWorkingDay] = useState(false);
  const [showLapsedToggle, setshowLapsedToggle] = useState<boolean>(false);

  const [enableExercise, setEnableExercise] = useState(false);
  const [enableExtendExercise, setEnableExtendExercise] = useState(false);
  const [enableSettlementLetter, setEnableSettlementLetter] = useState(false);
  const [loadingButton, setLoadingButton] = useState<string | null>(null);

  // there are no loaders
  const { data: _resignationDetails, refetch: getResignationDetails } =
    useGetResignationDetails(_id);
  const { mutate: resignationProcess, isLoading: resignationProcessLoading } =
    useResignationProcess();
  const { mutate: completeResignation, isLoading: completeResignationLoading } =
    useCompleteResignation();
  const { mutate: discardResignation, isLoading: discardResignationLoading } =
    useDiscardResignation();

  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();

  const [resignationDetails, setResignationDetails] =
    useState(_resignationDetails);
  const isPausedVestingDisabled =
    employee?.status === EmployementStatus.RESIGNED ||
    resignationDetails?.initiationDetails.grantTableDetails.length === 0;
  const [isFutureResignation, setIsFutureResignation] = useState(false);

  useEffect(() => {
    if (_resignationDetails)
      setResignationDetails({
        ..._resignationDetails,
        pauseVestingDetails: {
          ..._resignationDetails.pauseVestingDetails,
          pauseVestingDate: _resignationDetails.pauseVestingDetails
            .pauseVestingDate
            ? _resignationDetails.pauseVestingDetails.pauseVestingDate
            : format(new Date(), "yyyy-MM-dd"),
        },
      });
  }, [_resignationDetails]);

  useEffect(() => {
    const selectedDate = new Date(
      resignationDetails?.lastWorkingDayDetails?.lastWorkingDate!
    ).setHours(0, 0, 0, 0);

    const today = new Date().setHours(0, 0, 0, 0);

    if (selectedDate === today) setshowLapsedToggle(true);
    else setshowLapsedToggle(false);
  }, []);

  useEffect(() => {
    setEnablePauseVesting(
      resignationDetails?.pauseVestingDetails?.enablePauseVesting || false
    );

    setEnableLastWorkingDay(
      resignationDetails?.lastWorkingDayDetails?.setLastWorkingDay || false
    );
    setEnableExercise(
      resignationDetails?.exerciseOptionsDetails?.allowExercise || false
    );
    setEnableExtendExercise(
      resignationDetails?.extendExerciseDetails?.allowExtendExercise || false
    );
    setEnableSettlementLetter(
      resignationDetails?.fafLetterDetails?.generateLetter || false
    );
    const isLastWorkingDateFuture = !!(
      resignationDetails?.lastWorkingDayDetails?.lastWorkingDate &&
      resignationDetails.lastWorkingDayDetails.lastWorkingDate !== "" &&
      new Date(resignationDetails.lastWorkingDayDetails.lastWorkingDate) >
        new Date()
    );

    const isResignationDateFuture = !!(
      resignationDetails?.initiationDetails?.resignationDate &&
      resignationDetails.initiationDetails.resignationDate !== "" &&
      new Date(resignationDetails.initiationDetails.resignationDate) >
        new Date()
    );

    setIsFutureResignation(
      (isLastWorkingDateFuture &&
        resignationDetails?.lastWorkingDayDetails?.setLastWorkingDay) ||
        isResignationDateFuture
    );
  }, [resignationDetails]);

  const errorMessage = useError();

  const [dialog, setDialog] = useState<{
    open: boolean;
    index?: number;
    data?: string | RevertResignation;
    mode?:
      | "Extend Exercise"
      | "View Settlement Letter"
      | "Confirm Resignation"
      | "Confirm LWD"
      | "Confirm Discard";
  }>({
    open: false,
    mode: "Extend Exercise",
  });

  const completeResignationProcess = () => {
    completeResignation(_id, {
      onSuccess: () => {
        setActiveTab("Grant Details");
        toast("Completed Resignation Successfully", {
          type: "success",
          autoClose: 2000,
        });
        setDialog({
          open: false,
        });
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorsValidation?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
        setDialog({
          open: false,
        });
      },
    });
  };

  const handleLWD = (values: ResignationDto, lapsedCheckbox = false) => {
    const defaultResignationDetails = getDefaultResignationProcessObject(_id);
    defaultResignationDetails.lastWorkingDayDetails =
      values.lastWorkingDayDetails;
    defaultResignationDetails.lastWorkingDayDetails.setLastWorkingDay =
      enableLastWorkingDay;

    resignationProcess(defaultResignationDetails, {
      onSuccess: () => {
        if (!lapsedCheckbox) {
          toast("Updated Last Working Day", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          const lapseMessage = defaultResignationDetails.lastWorkingDayDetails
            .lapsedImmediately
            ? "Lapsed immediately is enabled"
            : "Lapsed immediately is disabled";

          toast(lapseMessage, {
            type: "success",
            autoClose: 2000,
          });
        }
        setDialog({
          open: false,
        });
        getResignationDetails();
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorsValidation?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
        setDialog({
          open: false,
        });
      },
    });
  };

  const handleSubmit = () => {
    setDialog({
      open: true,
      mode: "Confirm Resignation",
    });
  };

  const handlePauseVesting = (values: ResignationDto) => {
    const defaultResignationDetails = getDefaultResignationProcessObject(_id);
    defaultResignationDetails.pauseVestingDetails = values.pauseVestingDetails;
    defaultResignationDetails.pauseVestingDetails.enablePauseVesting =
      enablePauseVesting;
    resignationProcess(defaultResignationDetails, {
      onSuccess: () => {
        toast("Updated Pause Vesting Date", {
          type: "success",
          autoClose: 2000,
        });
        getResignationDetails();
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorsValidation?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
      },
    });
  };

  const handleResignationDate = (values: ResignationDto) => {
    const defaultResignationDetails = getDefaultResignationProcessObject(_id);
    defaultResignationDetails.initiationDetails.resignationDate =
      values.initiationDetails.resignationDate;
    defaultResignationDetails.initiationDetails.saveDetails = true;
    resignationProcess(defaultResignationDetails, {
      onSuccess: () => {
        toast("Updated Resignation Date", {
          type: "success",
          autoClose: 2000,
        });
        getResignationDetails();
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorsValidation?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
      },
    });
  };

  const handleExercise = (values: ResignationDto) => {
    const defaultResignationDetails = getDefaultResignationProcessObject(_id);
    defaultResignationDetails.exerciseOptionsDetails =
      values.exerciseOptionsDetails;
    defaultResignationDetails.exerciseOptionsDetails.allowExercise = true;
    defaultResignationDetails.exerciseOptionsDetails.getPreview = false;
    defaultResignationDetails.exerciseOptionsDetails.exerciseOptions = true;
    resignationProcess(defaultResignationDetails, {
      onSuccess: (data) => {
        toast("Exercise Request Sent Successfully", {
          type: "success",
          autoClose: 2000,
        });
        getResignationDetails();
      },
      onError: (err: any) => {
        const errMsg =
          err.response?.data?.errorsValidation?.errorRaw ||
          err.response?.data?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
      },
    });
  };

  const handleGenerateSettlementLetter = (values: ResignationDto) => {
    const defaultResignationDetails = getDefaultResignationProcessObject(_id);
    defaultResignationDetails.fafLetterDetails = values.fafLetterDetails;
    defaultResignationDetails.fafLetterDetails.saveDetails = true;
    defaultResignationDetails.fafLetterDetails.generateLetter = true;
    defaultResignationDetails.fafLetterDetails.uploadFAFLetter = false;
    resignationProcess(defaultResignationDetails, {
      onSuccess: (data) => {
        toast("F&F Letter Generated Successfully", {
          type: "success",
          autoClose: 2000,
        });
        getResignationDetails();
        setLoadingButton(null);
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorsValidation?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
      },
    });
  };

  const totalGranted = _.sumBy(
    resignationDetails?.initiationDetails.grantTableDetails,
    (g) => g.optionsGranted
  );

  const totalVested = _.sumBy(
    resignationDetails?.initiationDetails.grantTableDetails,
    (g) => g.optionsVested
  );
  const totalExercised = _.sumBy(
    resignationDetails?.initiationDetails.grantTableDetails,
    (g) => g.cashedOut + g.stockExercised
  );

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files?.[0];
    const base64File = (await convertToBase64(file)) as string;
    if (resignationDetails) {
      const defaultResignationDetails = getDefaultResignationProcessObject(_id);
      defaultResignationDetails.fafLetterDetails =
        resignationDetails.fafLetterDetails;
      defaultResignationDetails.fafLetterDetails.base64File = base64File;
      defaultResignationDetails.fafLetterDetails.saveDetails = true;
      defaultResignationDetails.fafLetterDetails.generateLetter = false;
      defaultResignationDetails.fafLetterDetails.uploadFAFLetter = true;
      resignationProcess(defaultResignationDetails, {
        onSuccess: () => {
          toast("F&F Letter Uploaded Successfully", {
            type: "success",
            autoClose: 2000,
          });
          getResignationDetails();
        },
        onError: (err: any) => {
          const errMsg = err.response?.data?.errorsValidation?.errorRaw;
          if (errMsg) {
            errorMessage.setMessage(errMsg);
            toast(errMsg, {
              type: "error",
              autoClose: 2000,
            });
          }
        },
      });
    }
  }

  const isDiscardDisabled =
    enablePauseVesting ||
    enableExercise ||
    enableExtendExercise ||
    enableLastWorkingDay ||
    enableSettlementLetter;
  const handleDiscardResignation = () => {
    setDialog({
      open: true,
      mode: "Confirm Discard",
      data: {
        employeeIds: [_id],
        note: "Discard Resignation",
        revertAccessBackToPrimaryEmailId: false,
        sendEmailForVestings: false,
        vestAsPerToday: false,
        resumeFutureVestings: false,
      } as RevertResignation,
    });
  };

  return (
    <VStack className="bg-white text-gray-dark">
      {!isResignationComplete ? (
        <HStack className="justify-between p-4 border-b border-solid">
          <Box className="flex flex-col ">
            <p className="text-lg font-semibold text-gray-600">
              Resignation Activities
            </p>
          </Box>
          <ButtonSecondary
            onClick={handleDiscardResignation}
            loading={discardResignationLoading}
          >
            Discard Resignation
          </ButtonSecondary>
        </HStack>
      ) : (
        <HStack className="justify-start p-4 border-b border-solid">
          <Box className="flex flex-col ">
            <p className="text-lg font-semibold text-gray-600">
              Resignation Summary
            </p>
          </Box>
        </HStack>
      )}
      {!isResignationComplete && resignationDetails && (
        <Formik
          key="resignationDetails"
          enableReinitialize={true}
          initialValues={resignationDetails}
          onSubmit={(values) => {
            handleSubmit();
          }}
        >
          {(formik) => (
            <Form key="resignationDetails">
              <Dialog
                open={dialog.open}
                onClose={() => {
                  setDialog({
                    open: false,
                  });
                }}
                maxWidth="md"
              >
                {dialog.mode === "Extend Exercise" &&
                dialog.index !== undefined ? (
                  <ExtendExercise
                    onClose={() => {
                      setDialog({ open: false });
                    }}
                    index={dialog.index}
                    values={formik.values}
                  />
                ) : dialog.mode === "View Settlement Letter" && dialog.data ? (
                  <LazyPdfDocument url={dialog.data as string} />
                ) : dialog.mode === "Confirm Resignation" ? (
                  <AlertDialog
                    message={"Are you sure you want to Complete Resignation?"}
                    error={false}
                    status={false}
                    primaryActionText="Submit"
                    secondaryActionText="Cancel"
                    onPrimaryAction={() => {
                      completeResignationProcess();
                    }}
                    onSecondaryAction={() => {
                      setDialog({
                        open: false,
                      });
                    }}
                    open={true}
                    loading={completeResignationLoading}
                  />
                ) : dialog.mode === "Confirm LWD" ? (
                  <AlertDialog
                    message={
                      "Are you sure you would like to save Last Working Day without Pause Vesting?"
                    }
                    error={false}
                    status={false}
                    primaryActionText="Save"
                    secondaryActionText="Cancel"
                    onPrimaryAction={() => {
                      handleLWD(formik.values);
                    }}
                    onSecondaryAction={() => {
                      setDialog({
                        open: false,
                      });
                    }}
                    open={true}
                    loading={resignationProcessLoading}
                  />
                ) : dialog.mode === "Confirm Discard" && dialog.data ? (
                  <RevertResignationDialog
                    open={dialog.open}
                    onClose={() =>
                      setDialog({
                        open: false,
                        data: "",
                      })
                    }
                    revertResignation={dialog.data as RevertResignation}
                    onSecondaryAction={() => {
                      setDialog({
                        open: false,
                        data: "",
                      });
                      setActiveTab("Grant Details");
                      getResignationDetails();
                    }}
                    isRevertResignation={false}
                  />
                ) : (
                  <div></div>
                )}
              </Dialog>
              <VStack className="p-4">
                <VStack className="gap-4 mb-8">
                  <Box>Enter resignation date</Box>
                  <HStack className="items-center">
                    <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                      Resignation Date
                    </Label>
                    <Input
                      type="date"
                      className={`mr-3 w-fit`}
                      {...formik.getFieldProps(
                        "initiationDetails.resignationDate"
                      )}
                    />
                    <ButtonPrimary1
                      className={"cursor-pointer"}
                      onClick={() => {
                        handleResignationDate(formik.values);
                      }}
                    >
                      Save
                    </ButtonPrimary1>
                  </HStack>
                  <div className="mb-4">
                    {formik.values.initiationDetails.grantTableDetails.length >
                      0 && (
                      <GrantStatusTable
                        grantStatus={
                          formik.values.initiationDetails.grantTableDetails
                        }
                        currencySymbol={currencySymbol}
                        currencyType={currencyType}
                      />
                    )}
                  </div>
                  {totalGranted - totalVested > 0 && (
                    <CollapsibleComponent
                      label={
                        openField !== "Pause Vesting"
                          ? `Would you like to pause vesting for ${employeeName}?`
                          : "Pause Vesting"
                      }
                      labelDetail={``}
                      isOpen={openField !== "Pause Vesting"}
                      onClick={() =>
                        openField !== "Pause Vesting"
                          ? setOpenField("Pause Vesting")
                          : setOpenField("")
                      }
                    >
                      <VStack className="gap-4">
                        <VStack className="">
                          <p className="font-medium text-md">
                            Would you like to pause vesting for {employeeName} ?
                          </p>
                          <p className="">
                            Pausing vesting would mean that no further options
                            will be vested for the Employee.
                          </p>
                        </VStack>
                        <HStack className="gap-40">
                          <HStack className="items-center gap-2">
                            <input
                              type="radio"
                              className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer
                `}
                              checked={enablePauseVesting}
                              onChange={(e) =>
                                setEnablePauseVesting(e.target.checked)
                              }
                            />
                            <Label className="mt-2">Yes</Label>
                          </HStack>
                          <HStack className="items-center gap-2">
                            <input
                              type="radio"
                              className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer`}
                              checked={!enablePauseVesting}
                              onChange={(e) =>
                                setEnablePauseVesting(!e.target.checked)
                              }
                            />
                            <Label className="mt-2">No</Label>
                          </HStack>
                        </HStack>
                        {enablePauseVesting && (
                          <>
                            <HStack className="items-center">
                              <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                                Pause Vesting Date
                              </Label>
                              <Input
                                type="date"
                                className={`mr-3 w-fit ${
                                  isPausedVestingDisabled &&
                                  "cursor-not-allowed"
                                }`}
                                disabled={isPausedVestingDisabled}
                                {...formik.getFieldProps(
                                  "pauseVestingDetails.pauseVestingDate"
                                )}
                              />
                              <ButtonPrimary1
                                disabled={isPausedVestingDisabled}
                                className={"cursor-pointer"}
                                onClick={() => {
                                  handlePauseVesting(formik.values);
                                }}
                              >
                                Save
                              </ButtonPrimary1>
                            </HStack>
                            {formik.values.initiationDetails.grantTableDetails
                              .length > 0 && (
                              <PauseVestingGrantTable
                                grantStatus={
                                  formik.values.initiationDetails
                                    .grantTableDetails
                                }
                                currencyType={currencyType}
                              />
                            )}
                          </>
                        )}
                      </VStack>
                    </CollapsibleComponent>
                  )}
                  <CollapsibleComponent
                    label={
                      openField !== "LWD"
                        ? `Would you like to set last working day for ${employeeName}?`
                        : "Last Working Day"
                    }
                    labelDetail={``}
                    isOpen={openField !== "LWD"}
                    onClick={() =>
                      openField !== "LWD"
                        ? setOpenField("LWD")
                        : setOpenField("")
                    }
                  >
                    <VStack className="gap-4">
                      <VStack className="">
                        <p className="font-medium text-md">
                          Would you like to set last working day for{" "}
                          {employeeName}?
                        </p>
                      </VStack>
                      <HStack className="gap-40">
                        <HStack className="items-center gap-2">
                          <input
                            type="radio"
                            className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer`}
                            checked={enableLastWorkingDay}
                            onChange={(e) =>
                              setEnableLastWorkingDay(e.target.checked)
                            }
                          />
                          <Label className="mt-2">Yes</Label>
                        </HStack>
                        <HStack className="items-center gap-2">
                          <input
                            type="radio"
                            className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer`}
                            checked={!enableLastWorkingDay}
                            onChange={(e) =>
                              setEnableLastWorkingDay(!e.target.checked)
                            }
                          />
                          <Label className="mt-2">No</Label>
                        </HStack>
                      </HStack>
                      {enableLastWorkingDay ? (
                        <HStack className="items-center">
                          <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                            Last Working Day
                          </Label>
                          <Input
                            type="date"
                            className={`w-fit mr-3`}
                            {...formik.getFieldProps(
                              "lastWorkingDayDetails.lastWorkingDate"
                            )}
                            onChange={(e) => {
                              const selectedDate = new Date(
                                e.target.value
                              ).setHours(0, 0, 0, 0);

                              const today = new Date().setHours(0, 0, 0, 0);

                              if (selectedDate === today) {
                                setshowLapsedToggle(true);
                                const defaultResignationDetails =
                                  getDefaultResignationProcessObject(_id);
                              } else {
                                setshowLapsedToggle(false);
                                const defaultResignationDetails =
                                  getDefaultResignationProcessObject(_id);
                              }

                              formik.handleChange(e);
                            }}
                          />
                          <ButtonPrimary1
                            className={"cursor-pointer"}
                            onClick={() => {
                              if (
                                !formik.values.pauseVestingDetails
                                  .pauseVestingDate
                              ) {
                                setDialog({
                                  open: true,
                                  mode: "Confirm LWD",
                                });
                              } else {
                                handleLWD(formik.values);
                              }
                            }}
                          >
                            Save
                          </ButtonPrimary1>
                        </HStack>
                      ) : (
                        <div className="italic text-gray-500 w-fit">
                          * Note: If not set then Resignation Date will be
                          considered as Last Working Day{" "}
                        </div>
                      )}
                    </VStack>
                    {showLapsedToggle && (
                      <div className="py-2">
                        <CheckBoxWithLabel
                          className="w-4 h-4 mr-1 cursor-pointer accent-orange-501 outline-hidden" // Reuse the same Tailwind class for orange color
                          label={
                            "Checking this will lapse all the unvested options immediately."
                          }
                          id="lapse-checkbox"
                          labelclassname="text-gray-800 ml-2"
                          checked={
                            formik.values.lastWorkingDayDetails
                              .lapsedImmediately
                          }
                          onChange={(e) => {
                            const lastWorkingDayDetails = {
                              ...formik.values.lastWorkingDayDetails,
                              lapsedImmediately: e.target.checked,
                            };
                            formik.setFieldValue(
                              "lastWorkingDayDetails",
                              lastWorkingDayDetails
                            );
                            if (
                              !formik.values.pauseVestingDetails
                                .pauseVestingDate
                            ) {
                              setDialog({
                                open: true,
                                mode: "Confirm LWD",
                              });
                            } else {
                              handleLWD(
                                {
                                  ...formik.values,
                                  lastWorkingDayDetails,
                                },
                                true
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                  </CollapsibleComponent>
                  {totalVested - totalExercised > 0 && (
                    <CollapsibleComponent
                      label={
                        openField !== "Exercise"
                          ? `Would you like to exercise options for ${employeeName} ?`
                          : "Exercise Options"
                      }
                      labelDetail={``}
                      isOpen={openField !== "Exercise"}
                      onClick={() =>
                        openField !== "Exercise"
                          ? setOpenField("Exercise")
                          : setOpenField("")
                      }
                    >
                      <VStack className="gap-4">
                        <VStack className="">
                          <p className="font-medium text-md">
                            Would you like to exercise options for{" "}
                            {employeeName} ?
                          </p>
                          <p>
                            Exercise options would mean that options will be
                            exercised for this employee by the company
                          </p>
                        </VStack>
                        <HStack className="gap-40">
                          <HStack className="items-center gap-2">
                            <input
                              type="radio"
                              className={`accent-orange-501 outline-hidden mr-1
                            ${
                              resignationDetails?.exerciseOptionsDetails
                                ?.allowExercise
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                              disabled={
                                resignationDetails?.exerciseOptionsDetails
                                  ?.allowExercise
                              }
                              checked={enableExercise}
                              onChange={(e) =>
                                setEnableExercise(e.target.checked)
                              }
                            />
                            <Label className="mt-2">Yes</Label>
                          </HStack>
                          <HStack className="items-center gap-2">
                            <input
                              type="radio"
                              className={`accent-orange-501 outline-hidden mr-1
                  ${
                    resignationDetails?.exerciseOptionsDetails?.allowExercise
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                              disabled={
                                resignationDetails?.exerciseOptionsDetails
                                  ?.allowExercise
                              }
                              checked={!enableExercise}
                              onChange={(e) =>
                                setEnableExercise(!e.target.checked)
                              }
                            />
                            <Label className="mt-2">No</Label>
                          </HStack>
                        </HStack>
                        {enableExercise && (
                          <VStack>
                            {!resignationDetails?.exerciseOptionsDetails
                              ?.allowExercise && (
                              <>
                                <HStack className="justify-between">
                                  <VStack>
                                    <Label>No. Of Options</Label>
                                    <Input
                                      type="number"
                                      className={`${
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                          ? "cursor-not-allowed"
                                          : ""
                                      }`}
                                      disabled={
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                      }
                                      {...formik.getFieldProps(
                                        "exerciseOptionsDetails.totalOptionsToExercise"
                                      )}
                                    />
                                  </VStack>
                                  <VStack>
                                    <Label>Exercise Date</Label>
                                    <Input
                                      type="date"
                                      className={`${
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                          ? "cursor-not-allowed"
                                          : ""
                                      }`}
                                      disabled={
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                      }
                                      {...formik.getFieldProps(
                                        "exerciseOptionsDetails.exerciseDate"
                                      )}
                                    />
                                  </VStack>
                                  <VStack>
                                    <Label>Fair Market Value</Label>
                                    <Input
                                      type="number"
                                      className={`${
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                          ? "cursor-not-allowed"
                                          : ""
                                      }`}
                                      disabled={
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                      }
                                      {...formik.getFieldProps(
                                        "exerciseOptionsDetails.fairMarketValue"
                                      )}
                                    />
                                  </VStack>
                                  <VStack>
                                    <Label>Exercise Type</Label>
                                    <Select
                                      className={`${
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                          ? "cursor-not-allowed"
                                          : ""
                                      }`}
                                      isPlaceHolderDisabled={false}
                                      options={["CASH", "STOCK"]}
                                      disabled={
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                      }
                                      {...formik.getFieldProps(
                                        "exerciseOptionsDetails.exerciseType"
                                      )}
                                    />
                                  </VStack>
                                </HStack>
                                <HStack className="justify-end mt-4">
                                  <ButtonPrimary1
                                    className={`w-fit`}
                                    disabled={
                                      resignationDetails?.exerciseOptionsDetails
                                        ?.allowExercise
                                    }
                                    onClick={() => {
                                      const resignationDetails =
                                        getDefaultResignationProcessObject(_id);
                                      resignationDetails.initiationDetails =
                                        formik.values.initiationDetails;
                                      resignationDetails.exerciseOptionsDetails =
                                        formik.values.exerciseOptionsDetails;
                                      resignationDetails.exerciseOptionsDetails.allowExercise =
                                        true;
                                      resignationDetails.exerciseOptionsDetails.getPreview =
                                        true;
                                      resignationProcess(resignationDetails, {
                                        onSuccess: (data) => {
                                          formik.setFieldValue(
                                            "exerciseOptionsDetails",
                                            data.data.exerciseOptionsDetails
                                          );
                                        },
                                        onError: (err: any) => {
                                          const errMsg =
                                            err.response?.data?.errorsValidation
                                              ?.errorRaw;
                                          if (errMsg) {
                                            errorMessage.setMessage(errMsg);
                                            toast(errMsg, {
                                              type: "error",
                                              autoClose: 2000,
                                            });
                                          }
                                        },
                                      });
                                    }}
                                  >
                                    Preview
                                  </ButtonPrimary1>
                                </HStack>
                              </>
                            )}
                            {_.sumBy(
                              formik.values?.exerciseOptionsDetails
                                .grantDetailsForExercises,
                              (e) => e.noOfOptionsToExercise
                            ) > 0 && (
                              <>
                                <ExerciseDetailsTable
                                  grantDetails={
                                    formik.values.exerciseOptionsDetails
                                      .grantDetailsForExercises
                                  }
                                />
                                {!resignationDetails?.exerciseOptionsDetails
                                  ?.allowExercise && (
                                  <HStack className="justify-end mt-10">
                                    <ButtonPrimary1
                                      className={`w-fit ${
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                          ? "cursor-not-allowed"
                                          : "cursor-pointer"
                                      }`}
                                      disabled={
                                        resignationDetails
                                          ?.exerciseOptionsDetails
                                          ?.allowExercise
                                      }
                                      onClick={() => {
                                        handleExercise(formik.values);
                                      }}
                                    >
                                      Exercise
                                    </ButtonPrimary1>
                                  </HStack>
                                )}
                              </>
                            )}
                          </VStack>
                        )}
                      </VStack>
                    </CollapsibleComponent>
                  )}
                  {totalVested - totalExercised > 0 && (
                    <CollapsibleComponent
                      label={
                        openField !== "Extend Exercise"
                          ? `Would you like to extend exercise for ${employeeName} ?`
                          : "Extend Exercise"
                      }
                      labelDetail={``}
                      isOpen={openField !== "Extend Exercise"}
                      onClick={() =>
                        openField !== "Extend Exercise"
                          ? setOpenField("Extend Exercise")
                          : setOpenField("")
                      }
                    >
                      <VStack className="gap-4">
                        <VStack className="">
                          <p className="font-medium text-md">
                            Would you like to extend exercise for {employeeName}
                            ?
                          </p>
                        </VStack>
                        <HStack className="gap-40">
                          <HStack className="items-center gap-2">
                            <input
                              type="radio"
                              className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer`}
                              checked={enableExtendExercise}
                              onChange={(e) =>
                                setEnableExtendExercise(e.target.checked)
                              }
                            />
                            <Label className="mt-2">Yes</Label>
                          </HStack>
                          <HStack className="items-center gap-2">
                            <input
                              type="radio"
                              className={`accent-orange-501 outline-hidden mr-1
                cursor-pointer`}
                              checked={!enableExtendExercise}
                              onChange={(e) =>
                                setEnableExtendExercise(!e.target.checked)
                              }
                            />
                            <Label className="mt-2">No</Label>
                          </HStack>
                        </HStack>
                      </VStack>
                      {enableExtendExercise &&
                        formik.values.extendExerciseDetails
                          .grantDetailsForExercises.length > 0 && (
                          <ExtendExerciseDetailsTable
                            grantDetails={
                              formik.values.extendExerciseDetails
                                .grantDetailsForExercises
                            }
                            setDialog={setDialog}
                            currencySymbol={currencySymbol}
                            currencyType={currencyType}
                          />
                        )}
                    </CollapsibleComponent>
                  )}
                  <CollapsibleComponent
                    label={
                      openField !== "Settlement Letter"
                        ? `Would you like to generate the full and final Settlement letter for ${employeeName}?`
                        : "Full and Final Settlement Letter"
                    }
                    labelDetail={``}
                    isOpen={openField !== "Settlement Letter"}
                    onClick={() =>
                      openField !== "Settlement Letter"
                        ? setOpenField("Settlement Letter")
                        : setOpenField("")
                    }
                  >
                    <VStack className="gap-4">
                      <VStack className="">
                        <p className="font-medium text-md">
                          Would you like to generate the full and final
                          Settlement letter for {employeeName}?
                        </p>
                      </VStack>
                      <HStack className="gap-40">
                        <HStack className="items-center gap-2">
                          <input
                            type="radio"
                            className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                            checked={enableSettlementLetter}
                            onChange={(e) =>
                              setEnableSettlementLetter(e.target.checked)
                            }
                          />
                          <Label className="mt-2">Yes</Label>
                        </HStack>
                        <HStack className="items-center gap-2">
                          <input
                            type="radio"
                            className="mr-1 cursor-pointer accent-orange-501 outline-hidden"
                            checked={!enableSettlementLetter}
                            onChange={(e) =>
                              setEnableSettlementLetter(!e.target.checked)
                            }
                          />
                          <Label className="mt-2">No</Label>
                        </HStack>
                      </HStack>
                      {enableSettlementLetter &&
                        formik.values.fafLetterDetails.grantsStatus.length >
                          0 && (
                          <VStack>
                            <p>The Grant table of the Employee is as follows</p>
                            <SettlementDetailsTable
                              grantDetails={
                                formik.values.fafLetterDetails.grantsStatus
                              }
                              currencySymbol={currencySymbol}
                              currencyType={currencyType}
                            />
                            <HStack className="mt-4">
                              <ButtonPrimary1
                                className={`mr-2`}
                                onClick={() => {
                                  setLoadingButton("generate"); // Set loading for this button
                                  handleGenerateSettlementLetter(
                                    resignationDetails
                                  );
                                }}
                                loading={loadingButton === "generate"}
                              >
                                {formik.values.fafLetterDetails
                                  .settlementLetterUrl
                                  ? "Re-Generate F&F Letter"
                                  : "Generate F&F Letter"}
                              </ButtonPrimary1>
                              <ButtonPrimary1
                                className={`mr-2`}
                                loading={loadingButton === "upload"}
                              >
                                <label className="px-2 py-2 rounded-lg cursor-pointer">
                                  <Input
                                    type="file"
                                    className="hidden"
                                    accept="application/pdf"
                                    onChange={(e) => {
                                      setLoadingButton("upload");
                                      handleUpload(e).finally(() =>
                                        setLoadingButton(null)
                                      );
                                    }}
                                  />
                                  Upload F&F letter
                                </label>
                              </ButtonPrimary1>
                              <ButtonPrimary1
                                onClick={() => {
                                  downloadS3File(
                                    formik.values.fafLetterDetails
                                      .settlementLetterUrl,
                                    `F&F Letter for ${employeeName}.pdf`
                                  );
                                }}
                              >
                                <Icon
                                  icon="material-symbols:download"
                                  width={24}
                                  color="#E85936"
                                />
                              </ButtonPrimary1>
                            </HStack>
                            {formik.values.fafLetterDetails
                              .settlementLetterUrl && (
                              <HStack className="mt-3">
                                <img
                                  className="h-8"
                                  src={pdfLogo}
                                  alt="PDF"
                                  color="#E85936"
                                />
                                <p
                                  className="ml-1 mr-2 underline cursor-pointer"
                                  onClick={() => {
                                    setDialog({
                                      open: true,
                                      mode: "View Settlement Letter",
                                      data: formik.values.fafLetterDetails
                                        .settlementLetterUrl,
                                    });
                                  }}
                                >
                                  F&F Letter for {employeeName}
                                </p>
                                <p className="mt-4 text-xxs">
                                  Updated at{" "}
                                  {format(
                                    new Date(
                                      resignationDetails.fafLetterDetails.generationTime
                                    ),
                                    "dd-MMM-yyyy hh:mm aaaaa'm'"
                                  )}
                                </p>
                              </HStack>
                            )}
                          </VStack>
                        )}
                    </VStack>
                  </CollapsibleComponent>

                  <Box className="p-4 rounded-sm shadow-lg">
                    <Box className="flex flex-row items-center justify-start space-x-2 align-middle">
                      <Box className="text-sm font-medium text-black">
                        Complete Resignation
                      </Box>
                    </Box>
                    <VStack className="gap-4 p-4">
                      <VStack className="gap-3">
                        <p className="font-medium text-md">
                          Please check the information below and complete the
                          resignation of the Employee
                        </p>
                        {!resignationDetails?.lastWorkingDayDetails
                          ?.setLastWorkingDay && (
                          <div className="italic text-gray-500 w-fit">
                            * Note: Resignation Date (
                            {new Date(
                              resignationDetails.initiationDetails.resignationDate
                            )
                              .toUTCString()
                              .slice(5, 17)}
                            ) will be considered as Last Working Date
                          </div>
                        )}
                      </VStack>
                      <CompleteResignationGrantsTable
                        grantStatus={formik.values.finalGrantsStatus}
                        currencySymbol={currencySymbol}
                        currencyType={currencyType}
                      />
                    </VStack>
                  </Box>
                </VStack>
                <HStack className="justify-between">
                  <div></div>
                  <HStack>
                    <ButtonPrimary
                      type="submit"
                      className="ml-3"
                      disabled={isFutureResignation}
                    >
                      Complete
                    </ButtonPrimary>
                  </HStack>
                </HStack>
              </VStack>
            </Form>
          )}
        </Formik>
      )}
      {isResignationComplete && resignationDetails && (
        <VStack className="gap-8 p-4">
          <Dialog
            open={dialog.open}
            onClose={() => {
              setDialog({
                open: false,
              });
            }}
            maxWidth="md"
          >
            {dialog.mode === "View Settlement Letter" && dialog.data && (
              <LazyPdfDocument url={dialog.data as string} />
            )}
          </Dialog>
          <HStack className="gap-12">
            <span className="text-sm font-normal whitespace-nowrap">
              Resignation Date
            </span>
            <span className="text-sm font-medium text-gray-600">
              {employee.resignationDate
                ? new Date(employee.resignationDate).toUTCString().slice(5, 17)
                : "-"}
            </span>
            <span className="text-sm font-normal ml-36 whitespace-nowrap">
              Last Working Day
            </span>
            <span className="text-sm font-medium text-gray-600">
              {new Date(employee.lastDay || "").toUTCString().slice(5, 17)}
            </span>
          </HStack>
          {employee.isPausedVesting && (
            <HStack>
              <span className="text-sm font-normal whitespace-nowrap">
                The vesting has been paused on{" "}
                <span className="text-sm font-medium text-gray-600">
                  {resignationDetails?.pauseVestingDetails.pauseVestingDate
                    ? new Date(
                        resignationDetails?.pauseVestingDetails.pauseVestingDate
                      )
                        .toUTCString()
                        .slice(5, 17)
                    : "-"}
                </span>
              </span>
            </HStack>
          )}
          {resignationDetails.finalGrantsStatus.length > 0 && (
            <ResignationSummaryTable
              grantStatus={resignationDetails.finalGrantsStatus}
              currencySymbol={currencySymbol}
              currencyType={currencyType}
            />
          )}
          <Box className="p-4 rounded-md shadow-lg">
            <Box className="text-sm font-medium text-black">
              {" "}
              Full and Final Settlement Letter
            </Box>
            <Box className="px-4 mt-2 space-y-2 border-gray-300">
              <HStack className="">
                <ButtonPrimary1
                  className={`mr-2`}
                  onClick={() => {
                    setLoadingButton("generate"); // Set loading for this button
                    handleGenerateSettlementLetter(resignationDetails);
                  }}
                  loading={loadingButton === "generate"}
                >
                  {resignationDetails.fafLetterDetails.settlementLetterUrl
                    ? "Re-Generate F&F Letter"
                    : "Generate F&F Letter"}
                </ButtonPrimary1>
                <ButtonPrimary1
                  className={``}
                  loading={loadingButton === "upload"}
                >
                  <label className="cursor-pointer">
                    <Input
                      type="file"
                      className="hidden"
                      accept="application/pdf"
                      onChange={(e) => {
                        setLoadingButton("upload");
                        handleUpload(e).finally(() => setLoadingButton(null));
                      }}
                    />
                    Upload F&F letter
                  </label>
                </ButtonPrimary1>
                {resignationDetails.fafLetterDetails.settlementLetterUrl && (
                  <ButtonPrimary1
                    className="ml-2"
                    onClick={() => {
                      downloadS3File(
                        resignationDetails.fafLetterDetails.settlementLetterUrl,
                        `F&F Letter for ${employeeName}.pdf`
                      );
                    }}
                  >
                    <Icon
                      icon="material-symbols:download"
                      width={24}
                      color="#E85936"
                    />
                  </ButtonPrimary1>
                )}
              </HStack>
              {resignationDetails.fafLetterDetails.settlementLetterUrl && (
                <HStack className="mt-3">
                  <img
                    className="h-8"
                    src={pdfLogo}
                    alt="PDF"
                    color="#E85936"
                  />
                  <p
                    className="ml-1 mr-2 underline cursor-pointer"
                    onClick={() => {
                      setDialog({
                        open: true,
                        mode: "View Settlement Letter",
                        data: resignationDetails.fafLetterDetails
                          .settlementLetterUrl,
                      });
                    }}
                  >
                    F&F Letter for {employeeName}
                  </p>
                  <p className="mt-4 text-xxs">
                    Updated at{" "}
                    {format(
                      new Date(
                        resignationDetails.fafLetterDetails.generationTime
                      ),
                      "dd-MMM-yyyy hh:mm aaaaa'm'"
                    )}
                  </p>
                </HStack>
              )}
            </Box>
          </Box>
        </VStack>
      )}
    </VStack>
  );
};

const GrantStatusTable = ({
  grantStatus,
  currencySymbol,
  currencyType,
}: {
  grantStatus: GrantStatusForResignation[];
  currencySymbol: string;
  currencyType: string;
}) => (
  <table className="w-full table-space">
    <thead className="text-xxs font-normal text-[#B8B7C9]">
      <tr className="border-b border-gray-200 border-dashed">
        <td className="px-2 py-2 align-top">GRANT INFO</td>
        <td className="px-2 py-2 align-top">OPTIONS GRANTED</td>
        <td className="px-2 py-2 align-top">VESTED</td>
        <td className="flex-wrap px-2 py-2 align-top">NEXT VESTING DATE</td>
        <td className="px-2 py-2 align-top">OPTIONS AVAILABLE FOR EXERCISE</td>
        <td className="px-2 py-2 align-top">LAST DATE TO EXERCISE</td>
      </tr>
    </thead>
    <tbody className="text-[#4E4E4E]">
      {grantStatus.map((grant) => (
        <tr
          key={grant.identifier}
          className="border-b border-gray-200 border-dashed "
        >
          <td className="px-2 py-2 align-top ">
            <HStack>
              <div className="mr-2 whitespace-nowrap">{grant.identifier}</div>
            </HStack>
            <p className="text-xxs">
              {new Date(grant.grantDate).toUTCString().slice(5, 17)}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">{grant.optionsGranted}</span>
            <p className="text-xxs">
              Price: {currencySymbol}
              {grant.strikePrice}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">{grant.optionsVested}</span>
            <HStack>
              <p className="mr-1 text-xxs whitespace-nowrap">
                Cashed Out: {grant.cashedOut}
              </p>
              <p className="mr-1 text-xxs whitespace-nowrap">
                | Stock Exercised: {grant.stockExercised}
              </p>
              <p className="mr-1 text-xxs whitespace-nowrap">
                | Forfeited: {grant.forfeited}
              </p>
            </HStack>
          </td>
          <td className="px-2 py-2 align-top">
            {grant.nextVestingDate
              ? new Date(grant.nextVestingDate).toUTCString().slice(5, 17)
              : "-"}
          </td>
          <td className="px-2 py-2 align-top">
            <span>{grant.optionsAvailableForExercise}</span>
          </td>
          <td className="px-2 py-2 align-top">
            {grant.newExpiryDate
              ? new Date(grant.newExpiryDate).toUTCString().slice(5, 17)
              : grant.currentExpiryDate
              ? new Date(grant.currentExpiryDate).toUTCString().slice(5, 17)
              : "-"}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const PauseVestingGrantTable = ({
  grantStatus,
  currencyType,
}: {
  grantStatus: GrantStatusForResignation[];
  currencyType: string;
}) => (
  <table className="w-full table-space">
    <thead className="text-xxs font-normal text-[#B8B7C9]">
      <tr className="border-b border-gray-200 border-dashed">
        <td className="px-2 py-2 align-top">GRANT INFO</td>
        <td className="px-2 py-2 align-top">NO. OF VESTING EVENTS LEFT</td>
        <td className="px-2 py-2 align-top">VESTING PAUSE DATE</td>
        <td className="flex-wrap px-2 py-2 align-top">PAUSED OPTIONS</td>
      </tr>
    </thead>
    <tbody className="text-[#4E4E4E]">
      {grantStatus.map((grant) => (
        <tr
          key={grant.identifier}
          className="border-b border-gray-200 border-dashed "
        >
          <td className="px-2 py-2 align-top ">
            <HStack>
              <div className="mr-2 whitespace-nowrap">{grant.identifier}</div>
            </HStack>
            <p className="text-xxs">
              {new Date(grant.grantDate).toUTCString().slice(5, 17)}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">{grant.noOfVestingEventsLeft}</span>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">
              {grant.vestingPauseDate
                ? new Date(grant.vestingPauseDate).toUTCString().slice(5, 17)
                : ""}
            </span>
          </td>
          <td className="px-2 py-2 align-top">{grant.pausedOptions}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ExerciseDetailsTable = ({
  grantDetails,
}: {
  grantDetails: GrantDetailsForExercise[];
}) => (
  <table className="w-full table-space">
    <thead className="text-xxs font-normal text-[#B8B7C9]">
      <tr className="border-b border-gray-200 border-dashed">
        <td className="px-2 py-2 align-top">GRANT INFO</td>
        <td className="px-2 py-2 align-top">EXERCISE TYPE</td>
        <td className="px-2 py-2 align-top">NO. OF OPTIONS</td>
        <td className="flex-wrap px-2 py-2 align-top">EXERCISE DATE</td>
        <td className="px-2 py-2 align-top">FAIR MARKET VALUE</td>
      </tr>
    </thead>
    <tbody className="text-[#4E4E4E]">
      {grantDetails.map((grant, index) => (
        <tr
          key={grant.identifier}
          className="border-b border-gray-200 border-dashed "
        >
          <td className="px-2 py-2 align-top ">
            <HStack>
              <div className="mr-2 whitespace-nowrap">{grant.identifier}</div>
            </HStack>
            <p className="text-xxs">
              {new Date(grant.grantDate).toUTCString().slice(5, 17)}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>{grant.exerciseType}</VStack>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>{grant.noOfOptionsToExercise}</VStack>
          </td>
          <td className="px-2 py-2 align-top">
            {grant.date ? new Date(grant.date).toUTCString().slice(5, 17) : ""}
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>{grant.fairMarketValue}</VStack>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ExtendExerciseDetailsTable = ({
  grantDetails,
  setDialog,
  currencySymbol,
  currencyType,
}: {
  grantDetails: GrantDetailsForExtendExercise[];
  setDialog: Function;
  currencySymbol: string;
  currencyType: string;
}) => (
  <table className="w-full table-space">
    <thead className="text-xxs font-normal text-[#B8B7C9]">
      <tr className="border-b border-gray-200 border-dashed">
        <td className="px-2 py-2 align-top">GRANT INFO</td>
        <td className="px-2 py-2 align-top">OPTIONS GRANTED</td>
        <td className="px-2 py-2 align-top">VESTED</td>
        <td className="flex-wrap px-2 py-2 align-top">
          OPTIONS AVAILABLE FOR EXERCISE
        </td>
        <td className="flex-wrap px-2 py-2 align-top">
          OPTIONS PENDING FOR EXERCISE APPROVAL
        </td>
        <td className="px-2 py-2 align-top">PLAN EXERCISE EXPIRY DATE</td>
        <td className="px-2 py-2 align-top">NEW EXPIRY DATE</td>
        <td className="px-2 py-2 align-top">EXTEND EXERCISE</td>
      </tr>
    </thead>
    <tbody className="text-[#4E4E4E]">
      {grantDetails.map((grant, index) => (
        <tr
          key={grant.identifier}
          className="border-b border-gray-200 border-dashed "
        >
          <td className="px-2 py-2 align-top ">
            <HStack>
              <div className="mr-2 whitespace-nowrap">{grant.identifier}</div>
            </HStack>
            <p className="text-xxs whitespace-nowrap">
              {new Date(grant.grantDate).toUTCString().slice(5, 17)}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>
              <span className="font-medium">{grant.optionsGranted}</span>
              <p className="text-xxs">
                Price: {currencySymbol}
                {grant.strikePrice}
              </p>
            </VStack>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>
              <span className="font-medium">{grant.optionsVested}</span>
              <HStack>
                <p className="mr-1 text-xxs whitespace-nowrap">
                  Cashed Out: {grant.cashedOut}
                </p>
                <p className="mr-1 text-xxs whitespace-nowrap">
                  | Stock Exercised: {grant.stockExercised}
                </p>
                <p className="mr-1 text-xxs whitespace-nowrap">
                  | Forfeited: {grant.forfeited}
                </p>
              </HStack>
            </VStack>
          </td>
          <td className="px-2 py-2 align-top">
            {grant.optionsAvailableForExercise}
          </td>
          <td className="px-2 py-2 align-top">{grant.noOfOptionsToExercise}</td>
          <td className="px-2 py-2 align-top">
            <VStack>
              {grant.currentExpiryDate
                ? new Date(grant.currentExpiryDate).toUTCString().slice(5, 17)
                : "-"}
            </VStack>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack className="whitespace-nowrap">
              {grant.newExpiryDate
                ? new Date(grant.newExpiryDate).toUTCString().slice(5, 17)
                : "-"}
            </VStack>
          </td>
          <td className="px-2 py-2 align-top">
            <p
              className={`text-orange-p underline cursor-pointer`}
              onClick={() => {
                setDialog({
                  open: true,
                  mode: "Extend Exercise",
                  index,
                });
              }}
            >
              Extend Exercise
            </p>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const SettlementDetailsTable = ({
  grantDetails,
  currencySymbol,
  currencyType,
}: {
  grantDetails: GrantDetailsForExtendExercise[];
  currencySymbol: string;
  currencyType: string;
}) => (
  <table className="w-full table-space">
    <thead className="text-xxs font-normal text-[#B8B7C9]">
      <tr className="border-b border-gray-200 border-dashed">
        <td className="px-2 py-2 align-top">GRANT INFO</td>
        <td className="px-2 py-2 align-top">OPTIONS GRANTED</td>
        <td className="px-2 py-2 align-top">VESTED</td>
        <td className="flex-wrap px-2 py-2 align-top">
          OPTIONS AVAILABLE FOR EXERCISE
        </td>
        <td className="flex-wrap px-2 py-2 align-top">
          OPTIONS PENDING FOR EXERCISE APPROVAL
        </td>
        <td className="px-2 py-2 align-top">PLAN EXERCISE EXPIRY DATE</td>
        <td className="px-2 py-2 align-top">NEW EXPIRY DATE</td>
      </tr>
    </thead>
    <tbody className="text-[#4E4E4E]">
      {grantDetails.map((grant, index) => (
        <tr
          key={grant.identifier}
          className="border-b border-gray-200 border-dashed "
        >
          <td className="px-2 py-2 align-top ">
            <HStack>
              <div className="mr-2 whitespace-nowrap">{grant.identifier}</div>
            </HStack>
            <p className="text-xxs">
              {new Date(grant.grantDate).toUTCString().slice(5, 17)}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>
              <span className="font-medium">{grant.optionsGranted}</span>
              <p className="text-xxs">
                Price: {currencySymbol}
                {grant.strikePrice}
              </p>
            </VStack>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>
              <span className="font-medium">{grant.optionsVested}</span>
              <HStack>
                <p className="mr-1 text-xxs whitespace-nowrap">
                  Cashed Out: {grant.cashedOut}
                </p>
                <p className="mr-1 text-xxs whitespace-nowrap">
                  | Stock Exercised: {grant.stockExercised}
                </p>
                <p className="mr-1 text-xxs whitespace-nowrap">
                  | Forfeited: {grant.forfeited}
                </p>
              </HStack>
            </VStack>
          </td>
          <td className="px-2 py-2 align-top">
            {grant.optionsAvailableForExercise}
          </td>
          <td className="px-2 py-2 align-top">{grant.noOfOptionsToExercise}</td>
          <td className="px-2 py-2 align-top">{grant.noOfOptionsToExercise}</td>
          <td className="px-2 py-2 align-top">
            <VStack>
              {grant.currentExpiryDate
                ? new Date(grant.currentExpiryDate).toUTCString().slice(5, 17)
                : "-"}
            </VStack>
          </td>
          <td className="px-2 py-2 align-top">
            <VStack>
              {grant.newExpiryDate
                ? new Date(grant.newExpiryDate).toUTCString().slice(5, 17)
                : "-"}
            </VStack>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const CompleteResignationGrantsTable = ({
  grantStatus,
  currencySymbol,
  currencyType,
}: {
  grantStatus: GrantStatusForResignation[];
  currencySymbol: string;
  currencyType: string;
}) => (
  <table className="w-full table-space">
    <thead className="text-xxs font-normal text-[#B8B7C9]">
      <tr className="border-b border-gray-200 border-dashed">
        <td className="px-2 py-2 align-top">GRANT INFO</td>
        <td className="px-2 py-2 align-top">OPTIONS GRANTED</td>
        <td className="px-2 py-2 align-top">VESTED</td>
        <td className="px-2 py-2 align-top">PAUSED OPTIONS</td>
        <td className="flex-wrap px-2 py-2 align-top">LAST DATE TO EXERCISE</td>
        <td className="px-2 py-2 align-top">OPTIONS TO BE EXERCISED</td>
      </tr>
    </thead>
    <tbody className="text-[#4E4E4E]">
      {grantStatus.map((grant) => (
        <tr
          key={grant.identifier}
          className="border-b border-gray-200 border-dashed "
        >
          <td className="px-2 py-2 align-top ">
            <HStack>
              <div className="mr-2 whitespace-nowrap">{grant.identifier}</div>
            </HStack>
            <p className="text-xxs">
              {new Date(grant.grantDate).toUTCString().slice(5, 17)}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">{grant.optionsGranted}</span>
            <p className="text-xxs">
              Price: {currencySymbol}
              {grant.strikePrice}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">{grant.optionsVested}</span>
            <HStack>
              <p className="mr-1 text-xxs whitespace-nowrap">
                Cashed Out: {grant.cashedOut}
              </p>
              <p className="mr-1 text-xxs whitespace-nowrap">
                | Stock Exercised: {grant.stockExercised}
              </p>
              <p className="mr-1 text-xxs whitespace-nowrap">
                | Forfeited: {grant.forfeited}
              </p>
            </HStack>
          </td>
          <td className="px-2 py-2 align-top">{grant.pausedOptions}</td>
          <td className="px-2 py-2 align-top">
            {grant.newExpiryDate
              ? new Date(grant.newExpiryDate).toUTCString().slice(5, 17)
              : grant.currentExpiryDate
              ? new Date(grant.currentExpiryDate).toUTCString().slice(5, 17)
              : "-"}
          </td>
          <td className="px-2 py-2 align-top">
            <span>{grant.noOfOptionsToExercise}</span>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ResignationSummaryTable = ({
  grantStatus,
  currencySymbol,
  currencyType,
}: {
  grantStatus: GrantStatusForResignation[];
  currencySymbol: string;
  currencyType: string;
}) => (
  <table className="w-full table-space">
    <thead className="text-xxs font-normal text-[#B8B7C9]">
      <tr className="border-b border-gray-200 border-dashed">
        <td className="px-2 py-2 align-top">GRANT INFO</td>
        <td className="px-2 py-2 align-top">OPTIONS GRANTED</td>
        <td className="px-2 py-2 align-top">OPTIONS VESTED</td>
        <td className="px-2 py-2 align-top">OPTIONS EXERCISED</td>
        <td className="flex-wrap px-2 py-2 align-top">LAST DATE TO EXERCISE</td>
      </tr>
    </thead>
    <tbody className="text-[#4E4E4E]">
      {grantStatus.map((grant) => (
        <tr
          key={grant.identifier}
          className="border-b border-gray-200 border-dashed "
        >
          <td className="px-2 py-2 align-top ">
            <HStack>
              <div className="mr-2 whitespace-nowrap">{grant.identifier}</div>
            </HStack>
            <p className="text-xxs">
              {new Date(grant.grantDate).toUTCString().slice(5, 17)}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">{grant.optionsGranted}</span>
            <p className="text-xxs">
              Price: {currencySymbol}
              {grant.strikePrice}
            </p>
          </td>
          <td className="px-2 py-2 align-top">
            <span className="font-medium">{grant.optionsVested}</span>
            <HStack>
              <p className="mr-1 text-xxs whitespace-nowrap">
                Cashed Out: {grant.cashedOut}
              </p>
              <p className="mr-1 text-xxs whitespace-nowrap">
                | Stock Exercised: {grant.stockExercised}
              </p>
              <p className="mr-1 text-xxs whitespace-nowrap">
                | Forfeited: {grant.forfeited}
              </p>
            </HStack>
          </td>
          <td className="px-2 py-2 align-top">
            {grant.optionsExercisedDuringResignation.toLocaleString(
              currencyType
            )}
          </td>
          <td className="px-2 py-2 align-top">
            {grant.newExpiryDate
              ? new Date(grant.newExpiryDate).toUTCString().slice(5, 17)
              : grant.currentExpiryDate
              ? new Date(grant.currentExpiryDate).toUTCString().slice(5, 17)
              : "-"}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const getDefaultResignationProcessObject = (employeeId: string) => {
  const resignationDto: ResignationDto = {
    initiationDetails: {
      employeeId,
      resignationDate: "",
      personalEmail: "",
      grantTableDetails: [],
      saveDetails: false,
    },
    pauseVestingDetails: {
      enablePauseVesting: false,
      pauseVestingDate: "",
    },
    lastWorkingDayDetails: {
      setLastWorkingDay: false,
      lastWorkingDate: "",
      lapsedImmediately: true,
    },
    exerciseOptionsDetails: {
      allowExercise: false,
      totalOptionsToExercise: 0,
      exerciseType: "",
      exerciseDate: "",
      fairMarketValue: 0,
      getPreview: true,
      validatePreview: false,
      exerciseOptions: false,
      grantDetailsForExercises: [],
    },
    extendExerciseDetails: {
      allowExtendExercise: false,
      grantDetailsForExercises: [],
    },
    fafLetterDetails: {
      generateLetter: false,
      grantsStatus: [],
      uploadFAFLetter: false,
      base64File: "",
      fileName: "",
      fileContentType: "",
      sendFAFLetterToEmployees: false,
      emailAddress: "",
      enableAcceptanceWindow: false,
      fafLetterStatus: FafLetterStatus.AcceptanceInitiated,
      sendLetterForApproval: false,
      signatureBase64: "",
      saveDetails: false,
      settlementLetterUrl: "",
      generationTime: new Date(),
    },
    finalGrantsStatus: [],
  };
  return resignationDto;
};
